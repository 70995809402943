<!-- Note that the OX library is VERY sensitive about the exact order of DOM elements. -->
<!-- Hence the reason the below HTML is not split up any further into separate components. -->

<ng-container *ngIf="isAuthenticated$ | async; else loading">
  <div class="ox-layout ox-layout--full-width">
    <header class="ox-layout__header" role="banner">
      <div class="ox-header-container">
        <a class="ox-header__logo" href="/" tabindex="1">
          <span class="ox-header__site-name">{{ 'model.header.TITLE' | translate }}</span>
        </a>
      </div>
    </header>

    <div class="ox-layout__primary-navigation-wrapper">
      <app-toolbar></app-toolbar>
    </div>

    <div class="ox-layout__main">
      <main class="ox-layout__main__content ox-container" role="main">
        <app-filter></app-filter>
        <app-dsb-grid></app-dsb-grid>
      </main>

      <footer role="footer" class="ox-layout__footer pb-3">
        <p class="ox-text--centered">
          <a [href]="environmentService.getCookieURL()" class="ox-link ox-link--small">
            {{'model.gdpr.COOKIE' | translate}}
          </a>|
          <a [href]="environmentService.getPrivacyURL()" class="ox-link  ox-link--small">
            {{'model.gdpr.PRIVACY' | translate}}
          </a>
        </p>
      </footer>
    </div>

  </div>
</ng-container>

<ng-template #loading>
  <div aria-label="Content loading" aria-live="polite" class="ox-is-loading my-5" role="status">
    <img alt="Loading..." aria-hidden="true" class="ox-loader" src="https://ox.qone.mateco.eu/assets/img/loader.svg"/>
  </div>
</ng-template>

<app-unauthorized-handler></app-unauthorized-handler>
