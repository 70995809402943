<div *ngFor="let tour of enhancedTours; first as isFirstTour; last as isLastTour; trackBy: tourId"
     [ngClass]="{
       'pb-2' : !isLastTour,
       'pt-2' : !isFirstTour,
       'horizontal-line' : !isLastTour
     }"
     class="row">
  <div class="col-sm-4">
    <strong [ngClass]="tour.externalTourId | highlight" class="text-dark mr-2">
      <a target="_blank" rel="noopener noreferrer" class="ox-link"
         [href]="tourUrl(tour.externalTourId)">
        {{tour.externalTourId}}</a>
    </strong>
    <small *ngIf="tour.isUnfinished">({{'model.dsbDetail.UNFINISHED'|translate}}
      , {{ tour.departureDate }})</small>
    <small *ngIf="tour.isUpcoming">({{'model.dsbDetail.UPCOMING'|translate}}
      , {{ tour.departureDate }})</small>
  </div>

  <div class="col-sm-15">
    <div *ngFor="let stop of tour.stops; first as isFirstStop; last as isLastStop; trackBy: stopId"
         [ngClass]="{
           'light': allActionPointsFinishedAtDifferentDate(stop),
           'indented-horizontal-line': !isLastStop
         }"
         class="row">
      <div [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }"
           class="col-sm-2">
        <small [ngClass]="{'font-italic': tour.isPlanned && !tour.isStarted}">
          {{ tour.isStarted ? stop.estimatedTimeOfArrival : stop.plannedTimeOfArrival }}
        </small>
      </div>
      <div [class]="'col-sm-2 text-center ' + verticalLineClass(isFirstStop, isLastStop)"
           [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }">
        <i [ngClass]="tourStopIcon(stop.stopStatus)"
           class="grey-20 align-middle hide-vertical-line"></i>
      </div>
      <div [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }"
           class="col-sm-12">
        <small [ngClass]="stop.name | highlight">{{stop.name}}</small>
      </div>
      <div [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }"
           class="col-sm-4 pl-4">
        <ng-container *ngFor="let actionPoint of stop.actionPoints; trackBy: objectId">
          <small *ngIf="actionPoint.transportType == 'DELIVERY'"
                 [ngClass]="{'light': isSingleActionPointFinishedAtDifferentDate(actionPoint, stop)}">
            <app-transport [actionPoint]="actionPoint"></app-transport>
          </small>
        </ng-container>
      </div>
      <div [ngClass]="{
             'pb-2': !isLastStop,
             'pt-2': !isFirstStop
           }"
           class="col-sm-4 pl-4">
        <ng-container *ngFor="let actionPoint of stop.actionPoints; trackBy: objectId">
          <small *ngIf="actionPoint.transportType == 'PICKUP' || actionPoint.transportType == 'TRANSFER'"
                 [ngClass]="{'light': isSingleActionPointFinishedAtDifferentDate(actionPoint, stop)}">
            <app-transport [actionPoint]="actionPoint"></app-transport>
          </small>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="col-sm-5">
    <app-issues-detail [issues]="tour.issues"></app-issues-detail>
  </div>
</div>
