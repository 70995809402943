import {Component, OnDestroy, ViewChild} from '@angular/core';
import {SelectOption} from '../template/multi-select-filter/select-option';
import {MultiSelectFilterComponent} from '../template/multi-select-filter/multi-select-filter.component';
import {NextDepotFilter} from './next-depot-filter';
import {Subject} from 'rxjs';
import {PollingDeliveryStatusService} from '../../services/polling-delivery-status-service.service';
import {takeUntil} from 'rxjs/operators';
import {DeliveryStatus} from '../../models/delivery-status.model';
import {MultiSelectFilter} from '../multi-select-filter';

@Component({
  selector: 'app-next-depot-filter',
  templateUrl: './next-depot-filter.component.html',
})
export class NextDepotFilterComponent implements OnDestroy {
  public nextDepotOptions: SelectOption[] = [];

  @ViewChild(MultiSelectFilterComponent)
  public multiSelectFilterComponent: MultiSelectFilterComponent;
  public nextDepotFilter: MultiSelectFilter = new NextDepotFilter();

  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private readonly deliveryStatusService: PollingDeliveryStatusService
  ) {
    this.deliveryStatusService.records
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((deliveryStatuses: DeliveryStatus[]) => {
        const uniqueNextDepots = Array.from(
          new Set(
            deliveryStatuses
              .filter((value: DeliveryStatus) => !!value.status.nextDepot)
              .map(
                (deliveryStatus: DeliveryStatus) =>
                  deliveryStatus.status.nextDepot
              )
          )
        );

        this.nextDepotOptions = uniqueNextDepots.map(
          (uniqueNextDepot: string) => {
            const previousNextDepotOption = this.nextDepotOptions.find(
              (previous: SelectOption) => previous.displayName === uniqueNextDepot);
            return {
              id: uniqueNextDepot,
              displayName: uniqueNextDepot,
              isSelected: previousNextDepotOption && previousNextDepotOption.isSelected,
            };
          }
        );

        this.nextDepotOptions.sort((a1, a2) =>
          a1.displayName > a2.displayName ? 1 : -1
        );
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearFilter(): void {
    this.multiSelectFilterComponent.clearFilter();
  }

  clearNextDepotOptions(nextDepotOptions: SelectOption[]): void {
    this.nextDepotOptions = nextDepotOptions;
  }
}
