import {Component, ViewChild} from '@angular/core';
import {GeneralSearchComponent} from './general-search/general-search.component';
import {FilterService} from '../services/filter.service';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {DepotFilterComponent} from './depot-filter/depot-filter.component';
import {DriverFilterComponent} from './driver-filter/driver-filter.component';
import {NextDepotFilterComponent} from './next-depot-filter/next-depot-filter.component';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {

  @ViewChild(GeneralSearchComponent) searchBar: GeneralSearchComponent;
  @ViewChild(DatePickerComponent) datePicker: DatePickerComponent;
  @ViewChild(DriverFilterComponent) driverFilter: DriverFilterComponent;
  @ViewChild(DepotFilterComponent) depotFilter: DepotFilterComponent;
  @ViewChild(NextDepotFilterComponent) nextDepotFilter: NextDepotFilterComponent;

  constructor(private readonly filterService: FilterService) {
  }

  get hasFilters() {
    return this.filterService.hasFilters;
  }

  clearFilters() {
    this.filterService.clearAllFilters();
    this.driverFilter.clearFilter();
    this.depotFilter.clearFilter();
    this.searchBar.clearSearch();
    this.datePicker.clearFilter();
    this.nextDepotFilter.clearFilter();
  }
}
