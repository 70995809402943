export class RowStatus {

  readonly action: Action;
  readonly driverId: string | null;

  private constructor(action: Action, driverId?: string) {
    this.action = action;
    this.driverId = driverId;
  }

  static collapseAll() {
    return new RowStatus(Action.COLLAPSE_ALL);
  }

  static expandAll() {
    return new RowStatus(Action.EXPAND_ALL);
  }

  static collapse(driverId: string) {
    return new RowStatus(Action.COLLAPSE, driverId);
  }

  static expand(driverId: string) {
    return new RowStatus(Action.EXPAND, driverId);
  }

}

export enum Action {
  EXPAND,
  COLLAPSE,
  EXPAND_ALL,
  COLLAPSE_ALL
}
