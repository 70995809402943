import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {filter, timer} from 'rxjs';
import {BroadcastingService} from './broadcasting.service';
import {ApplicationStatus} from '../models/application-status';

@Injectable({
  providedIn: 'root'
})
export class AutoUpdateService {

  constructor(swUpdate: SwUpdate,
              broadcastingService: BroadcastingService) {
    if (swUpdate.isEnabled) {
      console.log('Application will self-update if updates are available.');

      timer(2500, 30000).subscribe(() => swUpdate.checkForUpdate());

      swUpdate.versionUpdates.pipe(
        filter((event) => event.type === 'VERSION_READY')
      ).subscribe(ignored => {
        broadcastingService.onStatusChanged.next(ApplicationStatus.UPGRADE_AVAILABLE);
        broadcastingService.onStatusChanged.complete(); // Prevent other status updates from overwriting this one.

        timer(10000).subscribe(() => {
          swUpdate.activateUpdate().then(() => {
            document.location.reload();
          });
        });
      });
    } else {
      console.warn('Application will NOT self-update because browser does not support it.');
    }
  }
}
