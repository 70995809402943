import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BroadcastingService} from "../services/broadcasting.service";

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private broadcastingService : BroadcastingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Forbidden) {
          this.broadcastingService.onUnauthorized.next(true);
        } else {
          this.broadcastingService.onUnauthorized.next(false);
        }
        return throwError(() => error);
      })
    );
  }
}
