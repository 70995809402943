import {Component, Input} from '@angular/core';
import {Shift} from 'src/app/models/shift.model';
import {DateTime, Duration} from 'luxon';

@Component({
  selector: 'app-shift-detail',
  templateUrl: './shift-detail.component.html',
  styleUrls: ['./shift-detail.component.scss']
})
export class ShiftDetailComponent {
  @Input() public shift: Shift;

  get showDetails() {
    return this.shift && this.shift.startedAt != null;
  }

  get startedAt() {
    return this.shift.startedAt.toLocaleString(DateTime.TIME_SIMPLE);
  }

  get totalDrivingTime() {
    return this.hoursAndMinutes(this.shift.totalDrivingTime);
  }

  get totalHandlingTime() {
    return this.hoursAndMinutes(this.shift.totalHandlingTime);
  }

  private hoursAndMinutes(duration: Duration) {
    return {hours: duration.hours, minutes: duration.minutes};
  }

}
