import {ActivatedRoute} from '@angular/router';

export class ParamUtil {

  public static getOpcoFromURL(activatedRoute: ActivatedRoute): string {
    // should return undefined when parameter isn't found or left empty
    return activatedRoute.snapshot.queryParams.opco || undefined;
  }

  public static getLanguageFromURL(activatedRoute: ActivatedRoute): string {
    // should return undefined when parameter isn't found or left empty
    return activatedRoute.snapshot.queryParams.lang || undefined;
  }
}
