<div class="d-flex flex-row">
  <span class="ox-icon ox-icon--truck-unload ox-icon-small mr-1"></span>
  <progress [ngClass]="{ 'invisible': totalDeliveries == 0 }"
            aria-valuemin="0" class="m-0 ox-progress deliveries"
            max="{{totalDeliveries}}"
            role="progressbar" value="{{finishedDeliveries}}"></progress>

  <span class="ox-icon ox-icon--truck-load ox-icon-small ml-3 mr-1"></span>
  <progress [ngClass]="{ 'invisible': totalPickups == 0 }"
            aria-valuemin="0" class="m-0 ox-progress" max="{{totalPickups}}"
            role="progressbar" value="{{finishedPickups}}"></progress>
</div>
