import {Tour} from './tour.model';
import {DeliveryStatusRecordJson} from './json/DeliveryStatusRecordJson';
import {Driver} from './driver.model';
import {Shift} from './shift.model';
import {Status} from './status.model';
import {TransportStatus} from './transport-status.model';

export class DeliveryStatus {
  constructor(
    readonly driver: Driver,
    readonly tours: Tour[],
    readonly shift: Shift,
    readonly status: Status,
    readonly transportStatus: TransportStatus,
  ) {
  }

  get nbUnresolvedIssues() {
    return this.tours.map(tour => tour.nbUnresolvedIssues).reduce((a, b) => a + b, 0);
  }

  static fromJSON(json: DeliveryStatusRecordJson): DeliveryStatus {
    const tours = json.tours.map(tour => Tour.fromJSON(tour));

    return new DeliveryStatus(
      Driver.fromJSON(json.driver),
      tours,
      Shift.fromJSON(json.shift),
      Status.fromJSON(json.status),
      TransportStatus.fromJSON(json.transportStatus),
    );
  }

  toJSON(): any {
    return {
      driver: this.driver.toJSON(),
      shift: this.shift.toJSON(),
      tours: this.tours.map(tour => tour.toJSON()),
      status: this.status.toJSON(),
      transportStatus: this.transportStatus.toJSON()
    };
  }
}
