import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DeliveryStatus} from '../models/delivery-status.model';
import {BroadcastingService} from '../services/broadcasting.service';
import {PollingDeliveryStatusService} from '../services/polling-delivery-status-service.service';

@Component({
  selector: 'app-dsb-grid',
  templateUrl: './dsb-grid.component.html',
  styleUrls: ['./dsb-grid.component.scss']
})
export class DsbGridComponent implements OnDestroy, OnInit {
  isLoading;
  filteredAndSortedRecords: DeliveryStatus[] = [];

  private readonly _subscriptions: Subscription[] = [];

  constructor(private readonly broadcastingService: BroadcastingService,
              private readonly pollingDeliveryStatusService: PollingDeliveryStatusService) {
  }

  ngOnInit() {
    this._subscriptions.push(
      this.broadcastingService.filteredAndSortedRecords.subscribe(filteredAndSortedRecords => {
        this.filteredAndSortedRecords = filteredAndSortedRecords;
      }),
      this.pollingDeliveryStatusService.isLoading.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  driverId(index: number, item: DeliveryStatus) {
    return item.driver.driverId;
  }
}
