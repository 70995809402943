<div class="ox-header-container d-flex">
  <div class="flex-grow-1"></div>
  <app-application-status></app-application-status>
  <div class="flex-grow-1"></div>

  <app-opco-switch class="mr-3"></app-opco-switch>
  <app-language-switch class="mr-3"></app-language-switch>

  <div aria-haspopup="true" class="ox-dropmenu-wrapper ox-show-large-up">
    <input class="ox-dropmenu-toggle" id="toggle-account-nav" type="checkbox">
    <label aria-label="Toggle account nav"
           class="ox-dropmenu__trigger ox-button ox-button--secondary ox-button--icon-small mb-0"
           for="toggle-account-nav"
           role="button">
      <span class="ox-icon ox-icon--user"></span>
    </label>
    <div class="ox-dropmenu">
      <ul aria-label="Overflow" class="ox-dropmenu__list" data-floating-menu-direction="bottom" role="menu"
          tabindex="-1">
        <li *ngIf="user$ | async as profile" class="ox-dropmenu__option" role="presentation">
          <button class="ox-dropmenu__option__button" role="menuitem">
            <span class="ox-dropmenu__option__icon ox-icon ox-icon--fingerprint"></span>
            {{ profile.name }}
          </button>
        </li>
        <li class="ox-dropmenu__option ox-dropmenu__option--separator ox-dropmenu__option--error" role="presentation">
          <button (click)="logout()" class="ox-dropmenu__option__button" role="menuitem">
            <span class="ox-dropmenu__option__icon ox-icon ox-icon--power"></span>
            {{ 'model.header.LOG_OUT' | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
