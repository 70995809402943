import {InjectionToken} from '@angular/core';

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');

export interface AppConfig {
  auth0: {
    clientID: string
    domain: string
    audience: string
    scope: string
  },
  sentry: {
    enabled: boolean
    dsn: string
    environment: string
  },
  urls: {
    qplanning: string
  }
}
