import {DateTime} from 'luxon';
import {UnloadCargoIssueJson} from './json/UnloadCargoIssueJson';

export class UnloadCargoIssue {

  constructor(
    readonly objectId: string,
    readonly depot: string,
    readonly createdAt: DateTime,
    readonly resolvedAt: DateTime | null
  ) {
  }

  static fromJSON(json: UnloadCargoIssueJson): UnloadCargoIssue {
    return new UnloadCargoIssue(
      json.objectId,
      json.depot,
      DateTime.fromISO(json.createdAt),
      json.resolvedAt ? DateTime.fromISO(json.resolvedAt) : null,
    );
  }

  toJSON(): UnloadCargoIssueJson {
    return {
      objectId: this.objectId,
      depot: this.depot,
      createdAt: this.createdAt.toUTC().toISO(),
      resolvedAt: this.resolvedAt ? this.resolvedAt.toUTC().toISO() : null
    };
  }
}
