import {DeliveryStatus} from '../../models/delivery-status.model';
import {MultiSelectFilter} from '../multi-select-filter';

export class NextDepotFilter implements MultiSelectFilter {

  private _nextDepots: string[] = [];

  matches(deliveryStatus: DeliveryStatus): boolean {
    return this._nextDepots.indexOf(deliveryStatus.status.nextDepot) !== -1;
  }

  updateRecords(nextDepots: string[] | string): void {
    this._nextDepots = [...nextDepots];
  }
}
