import {SortingStrategy} from './sorting-strategy';
import {DeliveryStatus} from '../../models/delivery-status.model';
import {SortingOrder} from '../../models/enums/SortingOrder';
import {Tour} from '../../models/tour.model';
import {DateUtilService} from '../../services/date-util.service';

export class TourSortingStrategy extends SortingStrategy {

  constructor(
    private readonly dateUtilService: DateUtilService,
    sortingField: ((deliveryStatus: DeliveryStatus) => any)) {
    super(sortingField);
  }

  sort(records: DeliveryStatus[], sortingOrder: SortingOrder): DeliveryStatus[] {
    // null values should be put at the end of the sorting
    switch (sortingOrder) {
      case SortingOrder.ASCENDING:
        return records.sort((a, b) => {
          const leftValue = this.getExternalIdOfFirstPlannedTour(this.sortingField(a));
          const rightValue = this.getExternalIdOfFirstPlannedTour(this.sortingField(b));
          return !leftValue ? -1 : !rightValue ? 1 :
            (leftValue > rightValue) ? 1 : -1;
        });
      case SortingOrder.DESCENDING:
        return records.sort((a, b) => {
          const leftValue = this.getExternalIdOfFirstPlannedTour(this.sortingField(a));
          const rightValue = this.getExternalIdOfFirstPlannedTour(this.sortingField(b));
          return !leftValue ? 1 : !rightValue ? -1 :
            (rightValue > leftValue) ? 1 : -1;
        });
      default:
        return records;
    }
  }

  getExternalIdOfFirstPlannedTour(tours: Tour[]) {
    const plannedTours = tours.filter((tour) =>
      this.dateUtilService.isPlannedTour(tour)
    );

    // Can be empty in case a driver worked on an upcoming tour but has no planned tours for today, for example on a Saturday.
    return plannedTours.length ? plannedTours[0].externalTourId : null;
  }
}
