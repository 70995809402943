import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DeliveryStatusRecordJson} from '../models/json/DeliveryStatusRecordJson';
import {DeliveryStatus} from '../models/delivery-status.model';
import {ZonedDate} from '../models/ZonedDate';

@Injectable({
  providedIn: 'root'
})
export class DeliveryStatusService {

  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/api`;
  }

  getAllOpcos$(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/opco`);
  }

  getDeliveryStatus$(opco: string, zonedDate: ZonedDate): Observable<DeliveryStatus[]> {
    return this.http
      .get<DeliveryStatusRecordJson[]>(`${this.baseUrl}/delivery-status/${opco}`, {
        params: {
          date: zonedDate.date,
          timezone: zonedDate.timezone
        }
      })
      .pipe(map((list: DeliveryStatusRecordJson[]): DeliveryStatus[] => list.map(DeliveryStatus.fromJSON)));
  }
}
