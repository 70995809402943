import {SortingStrategy} from './sorting-strategy';
import {DeliveryStatus} from '../../models/delivery-status.model';
import {SortingOrder} from '../../models/enums/SortingOrder';

export class TimeSortingStrategy extends SortingStrategy {
  sort(records: DeliveryStatus[], sortingOrder: SortingOrder): DeliveryStatus[] {
    // null values should be put at the end of the sorting
    switch (sortingOrder) {
      case SortingOrder.ASCENDING:
        return records.sort((a, b) => {
          const leftValue = this.sortingField(a);
          const rightValue = this.sortingField(b);
          return !leftValue ? -1 : !rightValue ? 1 :
            (leftValue.valueOf() > rightValue.valueOf()) ? 1 : -1;
        });
      case SortingOrder.DESCENDING:
        return records.sort((a, b) => {
          const leftValue = this.sortingField(a);
          const rightValue = this.sortingField(b);
          return !leftValue ? 1 : !rightValue ? -1 :
            (rightValue.valueOf() > leftValue.valueOf()) ? 1 : -1;
        });
      default:
        return records;
    }
  }
}
