import {DeliveryStatus} from '../../models/delivery-status.model';
import {MultiSelectFilter} from '../multi-select-filter';

export class DriverFilter implements MultiSelectFilter {

  private _driverNames: string[] = [];

  matches(deliveryStatus: DeliveryStatus): boolean {
    return this._driverNames.indexOf(deliveryStatus.driver.accountName) !== -1;
  }

  updateRecords(driverNames: string[] | string): void {
    this._driverNames = [...driverNames];
  }
}
