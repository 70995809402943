import {Injectable, OnDestroy} from '@angular/core';
import {Tour} from '../models/tour.model';
import {BroadcastingService} from './broadcasting.service';
import {Subscription} from 'rxjs';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DateUtilService implements OnDestroy {

  private activeDate: DateTime | null;

  private readonly subscription: Subscription;

  constructor(broadcastingService: BroadcastingService) {
    this.subscription = broadcastingService.onDateChanged.subscribe((zonedDate) => {
      if (zonedDate) {
        this.activeDate = DateTime.fromISO(zonedDate.date).setZone(zonedDate.timezone);
      } else {
        this.activeDate = undefined;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isAtSelectedDate(date: DateTime | null) {
    if (!date || !this.activeDate) {
      return false;
    }

    return date.hasSame(this.activeDate, 'day');
  }

  isBeforeSelectedDate(datetime: DateTime | null) {
    if (!datetime || !this.activeDate) {
      return false;
    }

    return datetime.startOf('day') < this.activeDate.startOf('day');
  }

  isAfterSelectedDate(datetime: DateTime | null) {
    if (!datetime || !this.activeDate) {
      return false;
    }

    return datetime.startOf('day') > this.activeDate.startOf('day');
  }

  isUnfinishedTour(tour: Tour) {
    return this.isBeforeSelectedDate(tour.arrivalDateTime);
  }

  isUpcomingTour(tour: Tour) {
    return this.isAfterSelectedDate(tour.departureDateTime);
  }

  isPlannedTour(tour: Tour) {
    return !this.isUnfinishedTour(tour) && !this.isUpcomingTour(tour);
  }
}
