export enum TransportPurpose {
  RENTAL = 'RENTAL',
  SUBRENTAL = 'SUBRENTAL',
  STOCK_ROTATION = 'STOCK_ROTATION',
  CUSTOMER = 'CUSTOMER',
  SERVICE = 'SERVICE',
  SALES = 'SALES',
  PURCHASE = 'PURCHASE',
  FINANCIAL_LEASE = 'FINANCIAL_LEASE',
  OPERATIONAL_LEASE = 'OPERATIONAL_LEASE'
}
