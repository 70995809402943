import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {DateFilters} from 'src/app/models/enums/DateFilters';
import {BroadcastingService} from '../../services/broadcasting.service';
import {DummyDateFilter} from './dummy-date-filter';
import {FilterService} from '../../services/filter.service';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  readonly datePickerForm = new UntypedFormControl();
  readonly customDate = new UntypedFormControl();
  dateOptions: string[] = Object.values(DateFilters);
  showDatePicker = false;

  constructor(private readonly filterService: FilterService,
              private readonly broadcastingService: BroadcastingService) {
  }

  ngOnInit(): void {
    this.datePickerForm.setValue(DateFilters.TODAY);
    this.onChange();
  }

  onChange(): void {
    let date = this.localDateTime();

    const selectedFilter = this.datePickerForm.value;
    if (selectedFilter === DateFilters.TODAY) {
      // Current date is today
    } else if (selectedFilter === DateFilters.PREVIOUS_WORKING_DAY) {
      if (date.weekday === 1 /*Monday*/) {
        date = date.minus({days: 3}); // Friday
      } else if (date.weekday === 7 /*Sunday*/) {
        date = date.minus({days: 2}); // Friday
      } else {
        date = date.minus({days: 1});
      }
    } else if (selectedFilter === DateFilters.NEXT_WORKING_DAY) {
      if (date.weekday === 5 /*Friday*/) {
        date = date.plus({days: 3}); // Monday
      } else if (date.weekday === 6 /*Saturday*/) {
        date = date.plus({days: 2}); // Monday
      } else {
        date = date.plus({days: 1});
      }
    }

    this.customDate.reset();

    if (selectedFilter === DateFilters.CUSTOM_DATE) {
      this.showDatePicker = true;
    } else {
      this.showDatePicker = false;
      this.broadcastingService.onDateChanged.next({
        date: date.toISODate(),
        timezone: this.localTimezone()
      });
    }

    if (selectedFilter === DateFilters.TODAY) {
      this.filterService.clearFilter('DATE'); // Don't show clear button for today.
    } else {
      this.filterService.setFilter('DATE', new DummyDateFilter());  // Enable clear button.
    }
  }

  localDateTime() { // Separate method to enable override during tests.
    return DateTime.local();
  }

  localTimezone() { // Separate method to enable override during tests.
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  onCustomDateChange() {
    this.filterService.setFilter('DATE', new DummyDateFilter());
    this.broadcastingService.onDateChanged.next({
      date: this.customDate.value,
      timezone: this.localTimezone()
    });
  }

  clearFilter() {
    if (this.datePickerForm.value === DateFilters.TODAY) {
      return; // Prevents refreshing the data and showing the spinner.
    }

    this.datePickerForm.setValue(DateFilters.TODAY);
    this.onChange();
  }
}
