import {Component, OnDestroy} from '@angular/core';
import {BroadcastingService} from '../../services/broadcasting.service';
import {ApplicationStatus} from '../../models/application-status';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-application-status',
  templateUrl: './application-status.component.html',
  styleUrls: ['./application-status.component.scss']
})
export class ApplicationStatusComponent implements OnDestroy {

  readonly ApplicationStatus = ApplicationStatus;

  status: ApplicationStatus = ApplicationStatus.ONLINE;

  private readonly _subscription: Subscription;

  constructor(readonly broadcastingService: BroadcastingService) {
    this._subscription = broadcastingService.onStatusChanged.subscribe((status) => {
      this.status = status;
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

}
