import {timer} from 'rxjs';

export class RxjsUtil {

  static retryWithExponentialBackoff(retryCount: number, delayMs: number, maxDelayMs: number) {
    const nbRetries = Math.min(32, retryCount + 1); // Prevent potential overflow in combination with Math.pow
    return timer(Math.min(Math.pow(2, nbRetries) * delayMs, maxDelayMs));
  }

}
