import {ActionPoint} from './actionpoint.model';
import {StopJson} from './json/StopJson';
import {StopStatus} from './enums/StopStatus';
import {DateTime} from 'luxon';

export class Stop {
  constructor(
    private readonly _name: string | null,
    private readonly _plannedTimeOfArrival: DateTime | null,
    private readonly _estimatedTimeOfArrival: DateTime | null,
    private readonly _stopStatus: StopStatus,
    private readonly _actionPoints: ActionPoint[]
  ) {
  }

  get name() {
    return this._name;
  }

  get plannedTimeOfArrival() {
    if (this._plannedTimeOfArrival) {
      return this._plannedTimeOfArrival.toLocaleString(DateTime.TIME_SIMPLE);
    } else {
      return null;
    }
  }

  get estimatedTimeOfArrival() {
    if (this._estimatedTimeOfArrival) {
      return this._estimatedTimeOfArrival.toLocaleString(DateTime.TIME_SIMPLE);
    } else {
      return null;
    }
  }

  get actionPoints() {
    return this._actionPoints;
  }

  get stopStatus() {
    return this._stopStatus;
  }

  static fromJSON(json: StopJson): Stop {
    const actionPointsObject: ActionPoint[] = [];

    json.actionPoints.forEach(
      element => {
        actionPointsObject.push(
          ActionPoint.fromJSON(element)
        );
      });

    const stop = new Stop(
      json.name,
      json.plannedTimeOfArrival ? DateTime.fromISO(json.plannedTimeOfArrival) : null,
      json.estimatedTimeOfArrival ? DateTime.fromISO(json.estimatedTimeOfArrival) : null,
      json.stopStatus,
      actionPointsObject,
    );
    return stop;
  }

  toJSON(): any {
    return {
      name: this._name,
      plannedTimeOfArrival: this._plannedTimeOfArrival ? this._plannedTimeOfArrival.toUTC().toISO() : null,
      estimatedTimeOfArrival: this._estimatedTimeOfArrival ? this._estimatedTimeOfArrival.toUTC().toISO() : null,
      stopStatus: this._stopStatus,
      actionPoints: this._actionPoints.map(a => a.toJSON())
    };
  }
}
