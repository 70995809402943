import {DeliveryStatus} from '../../models/delivery-status.model';
import {Filter} from '../filter';

/**
 * Because date "filtering" happens when performing the API call,
 * this filter is nothing more than a dummy to indicate that the
 * selected date is not equal to "today" and that it can be cleared.
 */
export class DummyDateFilter implements Filter {

  matches(deliveryStatus: DeliveryStatus): boolean {
    return true;
  }
}
