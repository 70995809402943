import {Component} from '@angular/core';
import {BroadcastingService} from '../../services/broadcasting.service';
import {RowStatus} from '../../events/row-status';

@Component({
  selector: 'app-expand-collapse',
  templateUrl: './expand-collapse.component.html',
  styleUrls: ['./expand-collapse.component.scss']
})
export class ExpandCollapseComponent {

  constructor(private broadcastingService: BroadcastingService) {
  }

  sendExpandAll() {
    this.broadcastingService.onRowStatusChanged.next(RowStatus.expandAll());
  }

  sendCollapseAll() {
    this.broadcastingService.onRowStatusChanged.next(RowStatus.collapseAll());
  }

}
