import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, retry, Subscription} from 'rxjs';
import {DeliveryStatusService} from '../../services/delivery-status.service';
import {UntypedFormControl} from '@angular/forms';
import {BroadcastingService} from '../../services/broadcasting.service';
import {ParamUtil} from '../../util/param-util';
import {ActivatedRoute} from '@angular/router';
import {ApplicationStatus} from '../../models/application-status';
import {RxjsUtil} from '../../util/rxjs-util';

@Component({
  selector: 'app-opco-switch',
  templateUrl: './opco-switch.component.html',
  styleUrls: ['./opco-switch.component.scss']
})
export class OpcoSwitchComponent implements OnInit, OnDestroy {

  control = new UntypedFormControl();
  opcos: string[] = null;

  private _subscription: Subscription;

  constructor(private readonly broadcastingService: BroadcastingService,
              private readonly dsbService: DeliveryStatusService,
              private readonly activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this._subscription = this.fetchOpcosWithRetry();
  }

  /**
   * Returns an {@link Observable} to fetch the list of available opcos.
   * In case of errors (4xx/5xx), the request is retried and the application
   * is considered offline.
   */
  private fetchOpcosWithRetry(): Subscription {
    return this.dsbService.getAllOpcos$().pipe(
      retry({
        delay: (error, retryCount) => {
          console.error(`Error when querying list of available OPCOs: ${error.message || error}.`);
          this.broadcastingService.onStatusChanged.next(ApplicationStatus.OFFLINE);
          return RxjsUtil.retryWithExponentialBackoff(retryCount, 10000, 10000);
        }
      })
    ).subscribe({
      next: (opcos) => {
        this.opcos = opcos;
        this.setPreferredOpco(opcos);
        this.broadcastingService.onStatusChanged.next(ApplicationStatus.ONLINE);
      },
      error: (error) => {
        console.error(`Error when querying list of available OPCOs: ${error.message || error}.`)
        this.broadcastingService.onStatusChanged.next(ApplicationStatus.OFFLINE);
      }
    });
  }

  onOpcoChanged(opco: string): void {
    localStorage.setItem('opco', opco);
    this.broadcastingService.onOpcoCodeChanged.next(opco);
  }

  private setPreferredOpco(availableOpcos: string[] = []) {
    let preferredOpco = ParamUtil.getOpcoFromURL(this.activatedRoute) || localStorage.getItem('opco') || availableOpcos[0] || null;
    if (preferredOpco) {
      // Uppercasing the url parameter value so it doesn't matter if the value was upper or lower cased
      preferredOpco = preferredOpco.toUpperCase();
      const index = availableOpcos.indexOf(preferredOpco);
      if (index < 0) {
        preferredOpco = availableOpcos[0];
      }
    }

    this.control.setValue(preferredOpco);
    this.onOpcoChanged(preferredOpco);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
