import {Injectable} from "@angular/core";
import {AuthService} from "@auth0/auth0-angular";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {

  constructor(private readonly authService: AuthService) {}

  logout() {
    this.authService.logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: true,
      }
    });
  }
}
