import {Injectable, OnDestroy} from '@angular/core';
import {DeliveryStatus} from '../models/delivery-status.model';
import {BehaviorSubject, Subscription} from 'rxjs';
import {BroadcastingService} from './broadcasting.service';
import {SortingOrder} from '../models/enums/SortingOrder';
import {FilterService} from './filter.service';
import {SortingStrategy} from '../sortable-header/sorting-strategies/sorting-strategy';
import {ActiveStrategyAndOrder} from '../models/ActiveStrategyAndOrder';
import {GeneralStatusSortingStrategy} from '../sortable-header/sorting-strategies/general-status-sorting-strategy';

@Injectable({
  providedIn: 'root'
})
export class SortingService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _sortingStrategy: SortingStrategy;
  private _sortingOrder: SortingOrder;
  private _filteredRecords: DeliveryStatus[];
  public readonly activeStrategyAndOrder = new BehaviorSubject<ActiveStrategyAndOrder>(undefined);

  constructor(private readonly broadcastingService: BroadcastingService,
              private readonly filterService: FilterService) {
    this._subscriptions.push(this.filterService.filteredRecords.subscribe((filteredRecords) => {
        this._filteredRecords = filteredRecords;
        if (this._sortingOrder && this._sortingStrategy) {
          this.applySorting();
        } else {
          // set default sorting
          this.defaultSorting();
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setSorting(sortingStrategy: SortingStrategy, sortingOrder: SortingOrder) {
    this._sortingStrategy = sortingStrategy;
    this._sortingOrder = sortingOrder;
    this.activeStrategyAndOrder.next(new ActiveStrategyAndOrder(sortingStrategy, sortingOrder));
    this.applySorting();
  }

  defaultSorting() {
    this.setSorting(new GeneralStatusSortingStrategy(), SortingOrder.ASCENDING);
  }

  private applySorting() {
    // copy objects into new array to ensure records are not sorted
    const sortedRecords = [...this._filteredRecords];
    if (this._sortingOrder && this._sortingStrategy) {
      this._sortingStrategy.sort(sortedRecords, this._sortingOrder);
    }
    this.broadcastingService.filteredAndSortedRecords.next(sortedRecords);
  }
}
