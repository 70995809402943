<select (change)="onOpcoChanged($event.target.value)"
        *ngIf="opcos; else loading"
        [formControl]="control"
        aria-label="Select opco"
        class="ox-form__field ox-form__field--small">
  <option *ngFor="let opco of opcos" [value]="opco">
    {{opco}}
  </option>
</select>

<ng-template #loading>
  <div aria-label="Content loading"
       aria-live="polite"
       class="ox-is-loading mr-3"
       role="status">
    <img alt="Loading..."
         aria-hidden="true"
         class="ox-loader ox-loader--small"
         src="https://ox.qone.mateco.eu/assets/img/loader.svg"/>
  </div>
</ng-template>
