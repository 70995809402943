import {ActionPointJson} from './json/ActionPointJson';
import {FinishedBy} from './enums/FinishedBy';
import {TransportPurpose} from './enums/TransportPurpose';
import {DateTime} from 'luxon';

export class ActionPoint {
  constructor(
    readonly transportId: string | null,
    readonly objectId: string | null,
    readonly finishedBy: FinishedBy,
    readonly finishedAt: DateTime | null,
    readonly cargoDescription: string | null,
    readonly hasDriverNote: boolean,
    readonly transportType: string | null,
    readonly purpose: TransportPurpose | null
  ) {
  }

  static fromJSON(json: ActionPointJson): ActionPoint {
    return new ActionPoint(
      json.transportId,
      json.objectId,
      json.finishedBy,
      json.finishedAt ? DateTime.fromISO(json.finishedAt) : null,
      json.cargoDescription,
      json.hasDriverNote,
      json.transportType,
      json.purpose
    );
  }

  toJSON(): any {
    return {
      transportId: this.transportId,
      objectId: this.objectId,
      finishedBy: this.finishedBy,
      finishedAt: this.finishedAt ? this.finishedAt.toUTC().toISO() : null,
      cargoDescription: this.cargoDescription,
      hasDriverNote: this.hasDriverNote,
      transportType: this.transportType,
      purpose: this.purpose
    };
  }

}
