import {SortingStrategy} from './sorting-strategy';
import {DeliveryStatus} from '../../models/delivery-status.model';
import {SortingOrder} from '../../models/enums/SortingOrder';

export class NumericSortingStrategy extends SortingStrategy {
  sort(records: DeliveryStatus[], sortingOrder: SortingOrder): DeliveryStatus[] {
    switch (sortingOrder) {
      case SortingOrder.ASCENDING:
        return records.sort((a, b) => {
          const leftValue = this.sortingField(a);
          const rightValue = this.sortingField(b);
          return leftValue - rightValue;
        });
      case SortingOrder.DESCENDING:
        return records.sort((a, b) => {
          const leftValue = this.sortingField(a);
          const rightValue = this.sortingField(b);
          return rightValue - leftValue;
        });
      default:
        return records;
    }
  }
}
