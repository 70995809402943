import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {SearchUtil} from '../util/search-util';
import {Subscription} from 'rxjs';
import {BroadcastingService} from '../services/broadcasting.service';

@Pipe({
  name: 'highlight',
  pure: false
})
export class HighlightPipe implements PipeTransform, OnDestroy {

  private subscription: Subscription;
  private searchValue: string;

  constructor(private readonly broadcastingService: BroadcastingService) {
    this.subscription = broadcastingService.onSearchChanged.subscribe((value: string) => this.searchValue = value);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  transform(value: string): string {
    if (this.searchValue && value && SearchUtil.isContained(value, this.searchValue)) {
      return 'highlighted';
    } else {
      return '';
    }
  }

}
