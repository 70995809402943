<div class="d-flex flex-row">
  <div class="">
    <span class="ox-icon ox-icon--subitem ox-icon-small"></span>
  </div>
  <div class="text-dark ml-2">
    <div class="d-flex flex-column">
      <div *ngIf="record.driver.driverName" class="mb-2">
        <small><strong>{{'model.dsbDetail.DRIVER_NAME' | translate }}</strong></small><br/>
        <small [ngClass]="record.driver.driverName | highlight">{{ record.driver.driverName }}</small>
      </div>
      <div>
        <small><strong>{{'model.dsbDetail.DEPOT' | translate }}</strong></small><br/>
        <small *ngIf="record.driver.responsibleDepot">{{record.driver.responsibleDepot}}</small>
        <small *ngIf="!record.driver.responsibleDepot">{{'model.dsbDetail.NO_DEPOT'|translate}}</small>
      </div>
    </div>
  </div>
</div>
