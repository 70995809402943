<dialog class="ox-modal" open *ngIf="show$ | async">
  <div class="ox-modal__body">
    <h3 class="ox-modal__title">{{ "authentication.unauthorizedTitle" | translate }}</h3>
    <p>{{ "authentication.unauthorizedDescription" | translate }}</p>
  </div>
  <div class="ox-modal__footer">
    <div class="ox-button-group ox-button-group--reverse">
      <button type="submit" (click)="logout()" class="ox-button ox-button--primary">
        {{ 'model.header.LOG_OUT' | translate }}
      </button>
    </div>
  </div>
</dialog>
