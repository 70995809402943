import {DriverJson} from './json/DriverJson';

export class Driver {
  constructor(
    private readonly _driverId: string,
    private readonly _accountName: string,
    private readonly _driverName: string | null,
    private readonly _responsibleDepot: string,
  ) {
  }

  get driverId() {
    return this._driverId;
  }

  get accountName() {
    return this._accountName;
  }

  get driverName(): string | null {
    return this._driverName;
  }

  get responsibleDepot() {
    return this._responsibleDepot;
  }

  static fromJSON(json: DriverJson): Driver {
    return new Driver(
      json.driverId,
      json.accountName,
      json.driverName,
      json.responsibleDepot,
    );
  }

  toJSON(): DriverJson {
    return {
      driverId: this._driverId,
      accountName: this._accountName,
      driverName: this._driverName,
      responsibleDepot: this._responsibleDepot,
    };
  }
}
