import {Component, Input} from '@angular/core';
import {GeneralStatus, Status} from '../../../models/status.model';

@Component({
  selector: 'app-dsb-main-current-location',
  templateUrl: './dsb-main-current-location.component.html',
  styleUrls: ['./dsb-main-current-location.component.scss']
})
export class DsbMainCurrentLocationComponent {

  @Input() public status: Status | null;

  constructor() {
  }

  get isSingleLocation(): boolean {
    return this.status.generalStatus === GeneralStatus.FREE || this.status.generalStatus === GeneralStatus.HANDLING;
  }

  get isRoute(): boolean {
    return this.status.generalStatus === GeneralStatus.IN_TRANSIT;
  }
}
