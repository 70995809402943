import {SortingStrategy} from './sorting-strategy';
import {DeliveryStatus} from '../../models/delivery-status.model';
import {SortingOrder} from '../../models/enums/SortingOrder';

export class TransportStatusSortingStrategy extends SortingStrategy {

  constructor() {
    super((record) => record.transportStatus.percentageDone);
  }

  sort(records: DeliveryStatus[], sortingOrder: SortingOrder): DeliveryStatus[] {
    // Doesn't take into account any unfinished transport from earlier (from unfinished/upcoming tours)
    switch (sortingOrder) {
      case SortingOrder.ASCENDING:
        return records.sort((a, b) =>
          this.sortingField(a) - this.sortingField(b));
      case SortingOrder.DESCENDING:
        return records.sort((a, b) =>
          this.sortingField(b) - this.sortingField(a));
      default:
        return records;
    }
  }
}
