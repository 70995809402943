<div (click)="collapseOrExpandRow(!this.isCollapsed)"
     [ngClass]="{
       'border-bottom-full': isCollapsed,
       'border-bottom-indented': !isCollapsed
     }"
     class="row bg-white py-1 align-items-center cursor-pointer">
  <div class="col-sm-3">
    <app-dsb-main-driver [driver]="record.driver"></app-dsb-main-driver>
  </div>
  <div class="col-sm-2">
    <app-dsb-main-working-period [shift]="record.shift"></app-dsb-main-working-period>
  </div>
  <div class="col-sm-3">
    <app-dsb-main-tour [tours]="record.tours"></app-dsb-main-tour>
  </div>
  <div class="col-sm-2">
    <app-dsb-main-status [status]="record.status" [tours]="record.tours"></app-dsb-main-status>
  </div>
  <div class="col-sm-6">
    <app-dsb-main-current-location [status]="record.status"></app-dsb-main-current-location>
  </div>
  <div class="col-sm-4">
    <app-dsb-main-transport-progress [progress]="record.transportStatus"></app-dsb-main-transport-progress>
  </div>
  <div class="col-sm-2">
    <app-dsb-main-next-depot [status]="record.status"></app-dsb-main-next-depot>
  </div>
  <div class="col-sm-1">
    <app-dsb-main-issues [record]="record"></app-dsb-main-issues>
  </div>
  <div class="col-sm-1 text-right">
    <!-- This button intentionally has no click event because we already have a click event on the entire row. -->
    <button class="ox-button ox-button--plain ox-button--icon py-0 px-1"
            title="Show Details"
            type="button">
      <span aria-hidden="true"
            [ngClass]="{
              'ox-icon--chevron-left': isCollapsed,
              'ox-icon--chevron-down': !isCollapsed
            }"
            class="ox-button__icon ox-icon ox-icon-small"></span>
    </button>
  </div>
</div>
