import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FilterService} from '../../services/filter.service';

@Component({
  selector: 'app-general-transport-status',
  templateUrl: './general-transport-status.component.html',
  styleUrls: ['./general-transport-status.component.scss']
})
export class GeneralTransportStatusComponent implements OnInit, OnDestroy {

  private _recordSubscriptions: Subscription[] = [];

  finishedDeliveries: number;
  totalDeliveries: number;
  finishedPickups: number;
  totalPickups: number;
  isLoading: boolean;

  constructor(private readonly filterService: FilterService) {
  }

  ngOnDestroy() {
    this._recordSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this._recordSubscriptions.push(this.filterService.filteredRecords.subscribe(records => {
        this.resetValues();
        records.forEach(dsr => {
          this.finishedDeliveries += dsr.transportStatus.finishedDeliveries;
          this.totalDeliveries += dsr.transportStatus.totalDeliveries;
          this.finishedPickups += dsr.transportStatus.finishedPickups;
          this.totalPickups += dsr.transportStatus.totalPickups;
        });
      }),
      this.filterService.isLoading.subscribe(isLoading => {
        if (isLoading) {
          this.resetValues();
        }
        this.isLoading = isLoading;
      })
    );
  }

  get translationValuesForDeliveries() {
    return {
      finished: this.finishedDeliveries,
      total: this.totalDeliveries,
    };
  }

  get translationValuesForPickups() {
    return {
      finished: this.finishedPickups,
      total: this.totalPickups,
    };
  }

  resetValues() {
    this.finishedPickups = 0;
    this.totalDeliveries = 0;
    this.finishedDeliveries = 0;
    this.totalPickups = 0;
  }
}
