import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, ErrorHandler} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ToolbarComponent} from './header/toolbar.component';
import {FilterComponent} from './filter/filter.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OpcoSwitchComponent} from './header/opco-switch/opco-switch.component';
import {DsbGridComponent} from './dsb-grid/dsb-grid.component';
import {DsbMainRowComponent} from './dsb-grid/dsb-main-row/dsb-main-row.component';
import {DsbHeaderComponent} from './dsb-grid/dsb-header/dsb-header.component';
import {DsbDetailRowComponent} from './dsb-grid/dsb-detail-row/dsb-detail-row.component';
import {DsbMainDriverComponent} from './dsb-grid/dsb-main-row/dsb-main-driver/dsb-main-driver.component';
import {DsbMainWorkingPeriodComponent} from './dsb-grid/dsb-main-row/dsb-main-working-period/dsb-main-working-period.component';
import {DsbMainTourComponent} from './dsb-grid/dsb-main-row/dsb-main-tour/dsb-main-tour.component';
import {DsbMainStatusComponent} from './dsb-grid/dsb-main-row/dsb-main-status/dsb-main-status.component';
import {DsbMainCurrentLocationComponent} from './dsb-grid/dsb-main-row/dsb-main-current-location/dsb-main-current-location.component';
import {DsbMainTransportProgressComponent} from './dsb-grid/dsb-main-row/dsb-main-transport-progress/dsb-main-transport-progress.component';
import {DsbMainNextDepotComponent} from './dsb-grid/dsb-main-row/dsb-main-next-depot/dsb-main-next-depot.component';
import {DriverDetailComponent} from './dsb-grid/dsb-detail-row/driver-detail/driver-detail.component';
import {ShiftDetailComponent} from './dsb-grid/dsb-detail-row/shift-detail/shift-detail.component';
import {StatusDetailComponent} from './dsb-grid/dsb-detail-row/status-detail/status-detail.component';
import {ExpandCollapseComponent} from './filter/expand-collapse/expand-collapse.component';
import {GeneralTransportStatusComponent} from './filter/general-transport-status/general-transport-status.component';
import {DatePickerComponent} from './filter/date-picker/date-picker.component';
import {DateUtilService} from './services/date-util.service';
import {LanguageSwitchComponent} from './header/language-switch/language-switch.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DriverFilterComponent} from './filter/driver-filter/driver-filter.component';
import {GeneralSearchComponent} from './filter/general-search/general-search.component';
import {HighlightPipe} from './pipes/highlight.pipe';
import {DepotFilterComponent} from './filter/depot-filter/depot-filter.component';
import {SortableHeaderComponent} from './sortable-header/sortable-header.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {ApplicationStatusComponent} from './header/application-status/application-status.component';
import {NextDepotFilterComponent} from './filter/next-depot-filter/next-depot-filter.component';
import {MultiSelectFilterComponent} from './filter/template/multi-select-filter/multi-select-filter.component';
import {AuthClientConfig, AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {IssuesDetailComponent} from './dsb-grid/dsb-detail-row/issues-detail/issues-detail.component';
import {DsbMainIssuesComponent} from './dsb-grid/dsb-main-row/dsb-main-issues/dsb-main-issues.component';
import {TransportComponent} from './dsb-grid/dsb-detail-row/status-detail/transport/transport.component';
import {UnauthorizedHandlerComponent} from './authorization/unauthorized-handler.component';
import {AuthorizationInterceptor} from './authorization/authorization-interceptor.service';
import {initializeAuth0Client} from './config/initialize-auth0-client';
import {APP_CONFIG} from './config/app-config';
import * as Sentry from "@sentry/angular";

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedHandlerComponent,
    ToolbarComponent,
    FilterComponent,
    TransportComponent,
    DsbGridComponent,
    DsbHeaderComponent,
    DsbMainRowComponent,
    DsbDetailRowComponent,
    DsbMainDriverComponent,
    DsbMainWorkingPeriodComponent,
    DsbMainTourComponent,
    DsbMainStatusComponent,
    DsbMainCurrentLocationComponent,
    DsbMainTransportProgressComponent,
    DsbMainNextDepotComponent,
    DriverDetailComponent,
    ShiftDetailComponent,
    StatusDetailComponent,
    OpcoSwitchComponent,
    ExpandCollapseComponent,
    DatePickerComponent,
    GeneralTransportStatusComponent,
    LanguageSwitchComponent,
    DriverFilterComponent,
    DepotFilterComponent,
    ApplicationStatusComponent,
    LanguageSwitchComponent,
    GeneralSearchComponent,
    HighlightPipe,
    SortableHeaderComponent,
    NextDepotFilterComponent,
    MultiSelectFilterComponent,
    IssuesDetailComponent,
    DsbMainIssuesComponent
  ],
  imports: [
    AuthModule.forRoot(/* Config initialized via APP_INITIALIZER below. */),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately', // See https://github.com/angular/angular-cli/issues/13351#issuecomment-523674570
    })
  ],
  providers: [
    DateUtilService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuth0Client,
      deps: [AuthClientConfig, APP_CONFIG],
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true},
    {provide: ErrorHandler, useValue: Sentry.createErrorHandler()},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
