<ul class="list-unstyled">
  <li *ngFor="let issue of issues;" class="mb-2">
    <ng-container *ngIf="issue.resolvedAt; then resolved; else open"></ng-container>

    <ng-template #open>
            <span class="text-danger ox-has-tooltip"
                  [attr.data-tooltip]="'model.issues.createdAt' | translate: {dateTime: createdAt(issue)}">
                <span class="ox-icon ox-icon-small ox-icon--danger align-text-top mr-1"></span>
                <span>{{ 'model.issues.unloadCargoIssueOpen' | translate: issue }}</span>
            </span>
    </ng-template>

    <ng-template #resolved>
            <span class="text-success ox-has-tooltip"
                  [attr.data-tooltip]="'model.issues.resolvedAt' | translate: {dateTime: resolvedAt(issue)}">
                <span class="ox-icon ox-icon-small ox-icon--check align-text-top mr-1"></span>
                <span>{{ 'model.issues.unloadCargoIssueResolved' | translate: issue }}</span>
            </span>
    </ng-template>
  </li>
</ul>
