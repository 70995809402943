<div class="ox-filters__search">
  <div
    class="ox-filter__item ox-form__input-group ox-form__input-group--small ox-form__input-group--prepend mb-0">
    <input (ngModelChange)="onChange()"
           [formControl]="searchControl"
           [placeholder]="'model.filter.SEARCH' | translate"
           aria-label="General search for table content"
           class="ox-form__field mb-2"
           id="general-search"
           type="search"/>
    <label class="ox-form__input-group__icon" for="general-search">
      <span class="ox-icon ox-icon--search"></span>
    </label>
    <input class="ox-sr-only" type="submit" title="Search" value="search">
  </div>
</div>
