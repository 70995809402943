import {Component} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {FilterService} from '../../services/filter.service';
import {GeneralSearchFilter} from './general-search-filter';
import {BroadcastingService} from '../../services/broadcasting.service';
import {RowStatus} from '../../events/row-status';

@Component({
  selector: 'app-general-search',
  templateUrl: './general-search.component.html',
  styleUrls: ['./general-search.component.scss']
})
export class GeneralSearchComponent {

  readonly SEARCH_MIN_CHARACTERS = 3;

  readonly searchControl = new UntypedFormControl();

  constructor(private readonly filterService: FilterService,
              private readonly broadcastingService: BroadcastingService) {
  }

  clearSearch() {
    this.searchControl.setValue('');
  }

  onChange(): void {
    const searchValue = this.searchControl.value;
    if (searchValue.length >= this.SEARCH_MIN_CHARACTERS) {
      this.filterService.setFilter('SEARCH', new GeneralSearchFilter(searchValue));
      this.broadcastingService.onRowStatusChanged.next(RowStatus.expandAll());
      this.broadcastingService.onSearchChanged.next(searchValue);
    } else {
      this.filterService.clearFilter('SEARCH');
      this.broadcastingService.onRowStatusChanged.next(RowStatus.collapseAll());
      this.broadcastingService.onSearchChanged.next('');
    }
  }

}
