import {Injectable, OnDestroy} from '@angular/core';
import {DeliveryStatus} from '../models/delivery-status.model';
import {Filter} from '../filter/filter';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PollingDeliveryStatusService} from './polling-delivery-status-service.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService implements OnDestroy {

  private readonly _filters: Map<string, Filter> = new Map<string, Filter>();
  private _subscriptions: Subscription[] = [];
  public readonly filteredRecords = new BehaviorSubject<DeliveryStatus[]>([]);

  records: DeliveryStatus[];

  constructor(private readonly pollingDeliveryStatusService: PollingDeliveryStatusService) {
    this._subscriptions.push(this.pollingDeliveryStatusService.records.subscribe(records => {
        this.records = records;
        this.applyFilters();
      }
    ));
  }

  get isLoading() {
    return this.pollingDeliveryStatusService.isLoading;
  }

  get hasFilters() {
    return this._filters.size > 0;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setFilter(key: string, filter: Filter) {
    this._filters.set(key, filter);
    this.applyFilters();
  }

  clearFilter(key: string) {
    this._filters.delete(key);
    this.applyFilters();
  }

  clearAllFilters() {
    this._filters.clear();
    this.applyFilters();
  }

  private applyFilters() {
    if (this._filters.size === 0) {
      if (this.filteredRecords.value !== this.records) {
        this.filteredRecords.next(this.records);
      }
    } else {
      this.filteredRecords.next(this.records.filter(deliveryStatus => {
        let matched = true;
        this._filters.forEach(filter => {
          if (!filter.matches(deliveryStatus) || !matched) {
            matched = false;
          }
        });
        return matched;
      }));
    }
  }
}
