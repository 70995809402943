<app-multi-select-filter
  (clearOptions)="this.clearDepotOptions($event)"
  [filterKey]="'model.filter.DEPOT'"
  [filter]="this.depotFilter"
  [id]="'filter-depot'"
  [label]="'model.filter.DEPOT'"
  [noOptionsFoundLabel]="'model.filter.NO_DEPOT_FOUND'"
  [nullOptionLabel]="'model.filter.NO_DEPOT_SELECTED'"
  [selectOptions]="this.depotOptions">
</app-multi-select-filter>
