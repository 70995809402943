import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Tour} from '../../../models/tour.model';
import {DateUtilService} from 'src/app/services/date-util.service';
import {EnvironmentService} from '../../../services/environment.service';

@Component({
  selector: 'app-dsb-main-tour',
  templateUrl: './dsb-main-tour.component.html',
  styleUrls: ['./dsb-main-tour.component.scss']
})
export class DsbMainTourComponent implements OnChanges {

  @Input() tours: Tour[];

  firstPlannedTour: Tour;
  startArrivalTime: string;
  endArrivalTime: string;

  constructor(private readonly dateService: DateUtilService,
              private readonly environmentService: EnvironmentService) {
  }

  tourUrl(externalTourId: string) {
    return this.environmentService.qplanningTourUrl(externalTourId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.tours && !changes.tours.currentValue) {
      return;
    }

    const plannedTours = this.tours.filter(tour => this.dateService.isPlannedTour(tour));
    if (plannedTours.length > 0) {
      const firstTour = plannedTours[0];
      const firstStop = firstTour.stops[0];

      const lastTour = plannedTours[plannedTours.length - 1]; // In practice, first and last Tour is likely to be the same Tour.
      const lastStop = lastTour.stops[lastTour.stops.length - 1];

      this.firstPlannedTour = firstTour;
      this.startArrivalTime = firstStop.estimatedTimeOfArrival || firstStop.plannedTimeOfArrival;
      this.endArrivalTime = lastStop.estimatedTimeOfArrival || lastStop.plannedTimeOfArrival;
    }
  }
}
