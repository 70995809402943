import {Component, Input} from '@angular/core';
import {Shift} from 'src/app/models/shift.model';

@Component({
  selector: 'app-dsb-main-working-period',
  templateUrl: './dsb-main-working-period.component.html',
  styleUrls: ['./dsb-main-working-period.component.scss']
})
export class DsbMainWorkingPeriodComponent {

  @Input() shift: Shift;

  get totalWorkTime() {
    return {
      hours: this.shift.totalWorkTime.hours,
      minutes: this.shift.totalWorkTime.minutes
    };
  }

}
