import {ShiftJson} from './json/ShiftJson';
import {DateTime, Duration} from 'luxon';

export class Shift {

  constructor(
    private readonly _startedAt: DateTime | null,
    private readonly _totalDrivingTime: Duration | null,
    private readonly _totalHandlingTime: Duration | null,
    private readonly _totalWorkTime: Duration | null,
  ) {
  }

  get startedAt(): DateTime {
    if (this._startedAt) {
      return this._startedAt;
    } else {
      return null;
    }
  }

  get totalDrivingTime(): Duration {
    if (this._totalDrivingTime) {
      return this._totalDrivingTime;
    } else {
      return null;
    }
  }

  get totalWorkTime(): Duration {
    if (this._totalWorkTime) {
      return this._totalWorkTime;
    } else {
      return null;
    }
  }

  get totalHandlingTime(): Duration {
    if (this._totalHandlingTime) {
      return this._totalHandlingTime;
    } else {
      return null;
    }
  }

  static fromJSON(json: ShiftJson): Shift {
    return new Shift(
      json.startedAt ? DateTime.fromISO(json.startedAt) : null,
      json.totalDrivingTime ? Duration.fromISO(json.totalDrivingTime) : null,
      json.totalHandlingTime ? Duration.fromISO(json.totalHandlingTime) : null,
      json.totalWorkTime ? Duration.fromISO(json.totalWorkTime) : null
    );
  }

  toJSON(): ShiftJson {
    return {
      startedAt: this._startedAt ? this._startedAt.toUTC().toISO() : null,
      totalDrivingTime: this._totalDrivingTime ? this._totalDrivingTime.toISO() : null,
      totalHandlingTime: this._totalHandlingTime ? this._totalHandlingTime.toISO() : null,
      totalWorkTime: this._totalWorkTime ? this._totalWorkTime.toISO() : null
    };
  }
}
