import {SortingOrder} from './enums/SortingOrder';
import {SortingStrategy} from '../sortable-header/sorting-strategies/sorting-strategy';

/**
 * Custom object to store both SortingStrategy and SoringOrder in one object.
 * Used in combination with BehaviourSubject to be able to subscribe and know which SortingStrategy is currently active and what order.
 * You can't use BehaviourSubject with 2 objects so this is used instead.
 */


export class ActiveStrategyAndOrder {
  readonly sortingStrategy: SortingStrategy;
  readonly sortingOrder: SortingOrder;

  constructor(sortingStrategy: SortingStrategy, sortingOrder: SortingOrder) {
    this.sortingStrategy = sortingStrategy;
    this.sortingOrder = sortingOrder;
  }
}
