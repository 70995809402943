import {TransportStatusJson} from './json/TransportStatusJson';

export class TransportStatus {

  constructor(
    private readonly _finishedDeliveries: number,
    private readonly _totalDeliveries: number,
    private readonly _finishedPickups: number,
    private readonly _totalPickups: number
  ) {
  }

  get finishedDeliveries(): number {
    return this._finishedDeliveries;
  }

  get totalDeliveries(): number {
    return this._totalDeliveries;
  }

  get finishedPickups(): number {
    return this._finishedPickups;
  }

  get totalPickups(): number {
    return this._totalPickups;
  }

  get percentageDone(): number {
    const percentageDeliveries = this._finishedDeliveries / this._totalDeliveries;
    const percentagePickups = this._finishedPickups / this._totalPickups;
    if (!this._totalDeliveries) {
      return percentagePickups;
    }
    if (!this._totalPickups) {
      return percentageDeliveries;
    }
    return (percentageDeliveries + percentagePickups) / 2;
  }

  static fromJSON(json: TransportStatusJson): TransportStatus {
    return new TransportStatus(
      json.finishedDeliveries,
      json.totalDeliveries,
      json.finishedPickups,
      json.totalPickups
    );
  }

  toJSON(): TransportStatusJson {
    return {
      finishedDeliveries: this._finishedDeliveries,
      totalDeliveries: this._totalDeliveries,
      finishedPickups: this._finishedPickups,
      totalPickups: this._totalPickups
    };
  }
}
