import {Stop} from './stop.model';
import {TourJson} from './json/TourJson';
import {DateTime} from 'luxon';
import {UnloadCargoIssue} from './unload-cargo-issue.model';

export class Tour {

  constructor(
    private readonly _externalTourId: string,
    private readonly _departureDateTime: DateTime,
    private readonly _arrivalDateTime: DateTime,
    private readonly _valid: boolean,
    private readonly _started: boolean,
    private readonly _stops: Stop[],
    private readonly _issues: UnloadCargoIssue[]
  ) {
  }

  get externalTourId() {
    return this._externalTourId;
  }

  get departureDateTime() {
    return this._departureDateTime;
  }

  get arrivalDateTime() {
    return this._arrivalDateTime;
  }

  get valid() {
    return this._valid;
  }

  get started() {
    return this._started;
  }

  get stops() {
    return this._stops;
  }

  get issues() {
    return this._issues;
  }

  get nbUnresolvedIssues() {
    return this._issues.filter(issue => !issue.resolvedAt).length;
  }

  static fromJSON(json: TourJson): Tour {
    const stops = json.stops.map(stop => Stop.fromJSON(stop));
    const issues = json.issues.map(issue => UnloadCargoIssue.fromJSON(issue));

    return new Tour(
      json.externalTourId,
      DateTime.fromISO(json.departureDateTime),
      DateTime.fromISO(json.arrivalDateTime),
      json.valid,
      json.started,
      stops,
      issues
    );
  }

  toJSON(): any {
    return {
      externalTourId: this._externalTourId,
      departureDateTime: this._departureDateTime.toUTC().toISO(),
      arrivalDateTime: this._arrivalDateTime.toUTC().toISO(),
      valid: this._valid,
      started: this._started,
      stops: this._stops.map(s => s.toJSON()),
      issues: this._issues.map(i => i.toJSON())
    };
  }

}
