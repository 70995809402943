import {LocationJson, StatusJson} from './json/StatusJson';

export class Status {

  constructor(
    private readonly _generalStatus: GeneralStatus,
    private readonly _isLastStop: boolean,
    private readonly _location: Location | null,
    private readonly _nextDepot: string | null,
  ) {
  }

  get generalStatus(): GeneralStatus {
    return this._generalStatus;
  }

  get isLastStop(): boolean {
    return this._isLastStop;
  }

  get location(): Location | null {
    return this._location;
  }

  get nextDepot(): string | null {
    return this._nextDepot;
  }

  static fromJSON(json: StatusJson): Status {
    return new Status(
      json.generalStatus as GeneralStatus,
      json.lastStop,
      json.location === null ? null : Location.fromJSON(json.location),
      json.nextDepot,
    );
  }

  toJSON(): StatusJson {
    return {
      generalStatus: this._generalStatus.toString(),
      lastStop: this._isLastStop,
      location: this._location === null ? null : this._location.toJSON(),
      nextDepot: this._nextDepot,
    };
  }
}

export class Location {

  constructor(
    private readonly _lastVisitedLocation: string | null,
    private readonly _destinationLocation: string | null,
  ) {
  }

  get lastVisitedLocation(): string | null {
    return this._lastVisitedLocation;
  }

  get destinationLocation(): string | null {
    return this._destinationLocation;
  }

  static fromJSON(json: LocationJson) {
    return new Location(
      json.lastVisitedLocation,
      json.destinationLocation,
    );
  }

  toJSON(): LocationJson {
    return {
      lastVisitedLocation: this._lastVisitedLocation,
      destinationLocation: this._destinationLocation,
    };
  }
}

export enum GeneralStatus {
  NOT_STARTED = 'NOT_STARTED',
  HANDLING = 'HANDLING',
  IN_TRANSIT = 'IN_TRANSIT',
  FREE = 'FREE',
  FINISHED = 'FINISHED'
}

