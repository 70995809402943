import {DeliveryStatus} from '../../models/delivery-status.model';

export abstract class SortingStrategy {
  constructor(readonly sortingField: ((deliveryStatus: DeliveryStatus) => any)) {
  }

  equals(sortingStrategy: SortingStrategy) {
    if (!sortingStrategy) {
      return false;
    }

    if (this === sortingStrategy) {
      return true;
    }

    if (sortingStrategy instanceof this.constructor) {
      // Not a perfect equality check, but sufficient in practice.
      return this.sortingField.toString() === sortingStrategy.sortingField.toString();
    } else {
      return false;
    }
  }

  abstract sort(records: DeliveryStatus[], sortingOrder): DeliveryStatus[];
}
