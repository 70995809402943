import {Component, Input} from '@angular/core';
import {DeliveryStatus} from 'src/app/models/delivery-status.model';

@Component({
  selector: 'app-driver-detail',
  templateUrl: './driver-detail.component.html',
  styleUrls: ['./driver-detail.component.scss']
})
export class DriverDetailComponent {

  @Input() public record: DeliveryStatus;

}
