<div class="row border-bottom-3 pt-2">
  <app-sortable-header class="col-sm-3"
                       [title]="'model.dsbHeader.DRIVER'"
                       [sortingStrategy]="sortByDriverName">
  </app-sortable-header>
  <app-sortable-header class="col-sm-2"
                       [title]="'model.dsbHeader.WORKING'"
                       [sortingStrategy]="sortByWorkingTime">
  </app-sortable-header>
  <app-sortable-header class="col-sm-3"
                       [title]="'model.dsbHeader.TOURS'"
                       [sortingStrategy]="sortByTourId">
  </app-sortable-header>
  <app-sortable-header class="col-sm-8"
                       [title]="'model.dsbHeader.CURRENT_STATUS'"
                       [sortingStrategy]="sortByGeneralStatus">
  </app-sortable-header>
  <app-sortable-header class="col-sm-4"
                       [title]="'model.dsbHeader.TRANSPORT_STATUS'"
                       [sortingStrategy]="sortByTransportStatus">
  </app-sortable-header>
  <app-sortable-header class="col-sm-2"
                       [title]="'model.dsbHeader.NEXT_DEPOT'"
                       [sortingStrategy]="sortByNextDepot">
  </app-sortable-header>
  <app-sortable-header class="col-sm-1"
                       [title]="'model.dsbHeader.ISSUES'"
                       [sortingStrategy]="sortByUnresolvedIssueCount">
  </app-sortable-header>
  <app-expand-collapse class="col-sm-1 text-right"></app-expand-collapse>
</div>
