<div class="d-flex flex-row justify-content-between">
  <div class="ox-filters__filter">
    <div class="ox-filters__icon">
      <span class="ox-icon ox-icon--filter"></span>
    </div>
    <app-driver-filter></app-driver-filter>
    <app-depot-filter></app-depot-filter>
    <app-next-depot-filter></app-next-depot-filter>
    <app-date-picker></app-date-picker>
    <button (click)="clearFilters()" *ngIf="hasFilters"
            class="ox-button ox-button--small ox-button--secondary ml-2 filter-height"
            type="button" id="clear-filter-button">
      <span class="ox-icon ox-icon--cancel"></span>
      {{ 'model.filter.CLEAR_FILTERS' | translate }}
    </button>
  </div>
  <app-general-transport-status></app-general-transport-status>
  <app-general-search></app-general-search>
</div>
