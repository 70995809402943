import {DeliveryStatus} from '../../models/delivery-status.model';
import {Filter} from '../filter';
import {SearchUtil} from '../../util/search-util';

export class GeneralSearchFilter implements Filter {

  constructor(private readonly searchValue: string) {
  }

  matches(deliveryStatus: DeliveryStatus): boolean {
    return this.driverAccountNameContainsSearch(deliveryStatus, this.searchValue)
      || this.driverNameContainsSearch(deliveryStatus, this.searchValue)
      || this.stopsContainSearch(deliveryStatus, this.searchValue)
      || this.tourIdContainsSearch(deliveryStatus, this.searchValue)
      || this.machineNumberOrCargoDescriptionContainsSearch(deliveryStatus, this.searchValue);
  }

  private driverAccountNameContainsSearch(record: DeliveryStatus, searchValue: string): boolean {
    return SearchUtil.isContained(record.driver.accountName, searchValue);
  }

  private driverNameContainsSearch(record: DeliveryStatus, searchValue: string): boolean {
    if (record.driver.driverName) {
      return SearchUtil.isContained(record.driver.driverName, searchValue);
    } else {
      return false;
    }
  }

  private tourIdContainsSearch(record: DeliveryStatus, searchValue: string): boolean {
    return record.tours.some(tour => SearchUtil.isContained(tour.externalTourId, searchValue));
  }

  private machineNumberOrCargoDescriptionContainsSearch(record: DeliveryStatus, searchValue: string): boolean {
    return record.tours.some(tour =>
      tour.stops.some(stop =>
        stop.actionPoints.some(actionPoint =>
          (actionPoint.objectId !== null && SearchUtil.isContained(actionPoint.objectId, searchValue))
          || (actionPoint.cargoDescription !== null && SearchUtil.isContained(actionPoint.cargoDescription, searchValue))
        )
      )
    );
  }

  private stopsContainSearch(record: DeliveryStatus, searchValue: string): boolean {
    return record.tours.some(tour =>
      tour.stops.some(stop =>
        SearchUtil.isContained(stop.name, searchValue)
      )
    );
  }
}
