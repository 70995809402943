import {Component, Input} from '@angular/core';
import {Driver} from 'src/app/models/driver.model';

@Component({
  selector: 'app-dsb-main-driver',
  templateUrl: './dsb-main-driver.component.html',
  styleUrls: ['./dsb-main-driver.component.scss']
})
export class DsbMainDriverComponent {

  @Input() public driver: Driver;

  constructor() {
  }
}
