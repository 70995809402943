import {Component, inject} from '@angular/core';
import {EnvironmentService} from './services/environment.service';
import {AutoUpdateService} from './services/auto-update.service';
import {AuthService} from '@auth0/auth0-angular';
import {SentryService} from './config/sentry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private readonly authService = inject(AuthService);
  private readonly autoUpdateService = inject(AutoUpdateService); // Required to enable auto updates!
  private readonly sentryService = inject(SentryService); // Required to initialize Sentry!
  readonly environmentService = inject(EnvironmentService);

  isAuthenticated$ = this.authService.isAuthenticated$;

}
