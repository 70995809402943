import { DestroyRef, Inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AuthService, User } from "@auth0/auth0-angular";
import { filter } from "rxjs/operators";
import { APP_CONFIG, AppConfig } from './app-config';
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root',
})
export class SentryService {

  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
    authService: AuthService,
    destroyRef: DestroyRef,
  ) {
    authService.user$.pipe(
      takeUntilDestroyed(destroyRef),
      filter(user => !!user),
    ).subscribe((user) => this.initializeSentry(appConfig, user));
  }

  private initializeSentry(appConfig: AppConfig, user: User) {
    if (appConfig.sentry.enabled) {
      Sentry.init({
        dsn: appConfig.sentry.dsn,
        environment: appConfig.sentry.environment,
        transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
      });

      Sentry.getGlobalScope().setUser({ email: user.email });
    }
  }
}
