import {DeliveryStatus} from '../../models/delivery-status.model';
import {MultiSelectFilter} from '../multi-select-filter';

export class DepotFilter implements MultiSelectFilter {

  private _responsibleDepots: string[] = [];

  matches(deliveryStatus: DeliveryStatus): boolean {
    return this._responsibleDepots.indexOf(deliveryStatus.driver.responsibleDepot) !== -1;
  }

  updateRecords(responsibleDepots: string[] | string): void {
    this._responsibleDepots = [...responsibleDepots];
  }
}
