<div *ngIf="isSingleLocation">
  <span class="ox-icon ox-icon--location ox-icon-small align-text-top mr-1"></span>
  <small [ngClass]="status.location.lastVisitedLocation | highlight">{{status.location.lastVisitedLocation}}</small>
</div>
<div *ngIf="isRoute">
  <span class="ox-icon ox-icon--transport ox-icon-small align-text-top mr-1"></span>
  <small [ngClass]="status.location.lastVisitedLocation | highlight">{{status.location.lastVisitedLocation}} </small>
  <span class="ox-icon ox-icon--arrow-right ox-icon-small align-text-top mr-1"></span>
  <small [ngClass]="status.location.destinationLocation | highlight"> {{status.location.destinationLocation}}</small>
</div>
