@if (firstPlannedTour) {
  <strong [ngClass]="firstPlannedTour.externalTourId | highlight" class="text-dark mr-2">
    <!-- stopPropagation() is to prevent the underlying click event from triggering (row click event) -->
    <a (click)="$event.stopPropagation();"
       target="_blank" rel="noopener noreferrer" class="ox-link"
       [href]="tourUrl(firstPlannedTour.externalTourId)">
      {{ firstPlannedTour.externalTourId }}</a>
  </strong>

  @if (firstPlannedTour.valid) {
    <small>{{ startArrivalTime }}</small>
    <span class="ox-icon ox-icon--chevron-right ox-icon-small"></span>
    <small>{{ endArrivalTime }}</small>
  } @else {
    <span class="ox-icon ox-icon--error ox-icon-small align-text-top mr-1"></span>
    <small>{{ 'model.dsbMain.TOUR_INVALID' | translate }}</small>
  }
} @else {
  <small>{{ 'model.dsbMain.NO_TOUR_PLANNED' | translate }}</small>
}
