<div [ngClass]="{'hidden': isCollapsed}"
     class="row bg-white border-bottom-50 py-3">
  <div class="col-sm-3">
    <app-driver-detail [record]="record"></app-driver-detail>
  </div>
  <div class="col-sm-2">
    <app-shift-detail [shift]="record.shift"></app-shift-detail>
  </div>
  <div class="col-sm-19">
    <app-status-detail [tours]="record.tours"></app-status-detail>
  </div>
</div>
