import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AppComponent} from './app.component';
import {HttpsGuard} from './guards/https-guard.service';
import {AuthGuard} from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: '**',
    component: AppComponent,
    canActivate: [HttpsGuard, AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
