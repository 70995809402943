export enum Locales {
  EN_US = 'en-US',
  NL_NL = 'nl-NL',
  NL_BE = 'nl-BE',
  FR_LU = 'fr-LU',
  FR_BE = 'fr-BE',
  DE_LU = 'de-LU',
  ES_MX = 'es-MX',
  CS_CZ = 'cs-CZ',
  PL_PL = 'pl-PL',
  HU_HU = 'hu-HU'
}
