<div class="d-flex flex-column text-dark" *ngIf="showDetails">
  <small><strong>{{'model.dsbDetail.START_WORKING'|translate}}</strong></small>
  <small>{{ startedAt }}</small>

  <small class="mt-2"><strong>{{'model.dsbDetail.DRIVING'|translate}}</strong></small>
  <small>{{ 'locale.hours_and_minutes' | translate: totalDrivingTime }}</small>

  <small class="mt-2"><strong>{{'model.dsbDetail.HANDLING'|translate}}</strong></small>
  <small>{{ 'locale.hours_and_minutes' | translate: totalHandlingTime }}</small>
</div>
