import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild,} from '@angular/core';
import {SelectOption} from './select-option';
import {FilterService} from '../../../services/filter.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {BroadcastingService} from '../../../services/broadcasting.service';
import {TranslateService} from '@ngx-translate/core';
import {MultiSelectFilter} from '../../multi-select-filter';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss'],
})
export class MultiSelectFilterComponent implements OnDestroy, OnChanges {
  private static MAX_LENGTH_OF_DISPLAY_NAME: number = 50;

  @Input() public id: string;
  @Input() public label: string;
  @Input() public noOptionsFoundLabel: string;
  @Input() public nullOptionLabel: string;
  @Input() public filter: MultiSelectFilter;
  @Input() public filterKey: string;
  @Input() public selectOptions: SelectOption[] = [];
  @Output() public clearOptions = new EventEmitter<SelectOption[]>();

  public triggerActive: boolean;
  public labelInput: string;

  readonly selectOptionsSubject = new BehaviorSubject([]);
  readonly filterInput = new UntypedFormControl('');

  private readonly _subscriptions: Subscription[] = [];
  @ViewChild('filterToggle', {static: false})
  private filterToggle: ElementRef;

  constructor(
    private readonly filterService: FilterService,
    private readonly broadcastingService: BroadcastingService,
    private readonly translateService: TranslateService
  ) {
    this._subscriptions.push(
      broadcastingService.onOpcoCodeChanged.subscribe(() => {
        this.selectOptions = [];
        this.clearOptions.emit([]);
        this.clearFilter();
      }),
      this.translateService.onLangChange.subscribe(() => {
        if (this.getSelectedDisplayNamesForLabel(false).indexOf(null) !== -1) {
          this.applyFilter();
        }
      }),
      this.filterInput.valueChanges.subscribe((inputText: string) => {
        if (!inputText?.trim()) {
          this.selectOptionsSubject.next(this.selectOptions);
        } else {
          const filteredOptions = this.selectOptions.filter(
            (option: SelectOption) => {
              return option.displayName
                ?.toUpperCase()
                .includes(inputText?.toUpperCase().trim());
            }
          );
          this.selectOptionsSubject.next(filteredOptions);
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterInput.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  multiSelectId(index: number, item: SelectOption) {
    return item.id;
  }

  clearFilter() {
    this.filterService.clearFilter(this.filterKey);
    this.labelInput = '';
    this.triggerActive = false;
    this.resetSelectedOptions();
    this.closeFilter();
  }

  applyFilter(): void {
    const translatedDisplayNames = this.getSelectedDisplayNamesForLabel(
      true
    ).join(', ');
    this.applyLabelOnFilter(translatedDisplayNames);

    if (translatedDisplayNames.length > 0) {
      this.filter.updateRecords(this.getSelectedDisplayNamesForLabel(false));
      this.filterService.setFilter(this.filterKey, this.filter);
    } else {
      this.filterService.clearFilter(this.filterKey);
      this.triggerActive = false;
      this.labelInput = '';
    }
    this.closeFilter();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public getSelectedDisplayNamesForLabel(
    translateNullValue: boolean
  ): string[] {
    const displayNames = this.selectOptions
      .filter((option: SelectOption) => option.isSelected)
      .map((option: SelectOption) => option.displayName);

    if (translateNullValue && displayNames?.length > 0) {
      return displayNames.map((value: string) => {
        return value
          ? value
          : this.translateService.instant(this.nullOptionLabel);
      });
    } else {
      return displayNames;
    }
  }

  private applyLabelOnFilter(translatedDisplayNames: string) {
    this.triggerActive = true;
    this.labelInput = ': ';
    if (
      translatedDisplayNames.length >
      MultiSelectFilterComponent.MAX_LENGTH_OF_DISPLAY_NAME
    ) {
      const lastIndexOfSeparatorBeforeCutOff = translatedDisplayNames
        .substring(0, MultiSelectFilterComponent.MAX_LENGTH_OF_DISPLAY_NAME)
        .lastIndexOf(',');
      this.labelInput +=
        translatedDisplayNames.substring(0, lastIndexOfSeparatorBeforeCutOff) +
        ', ...';
    } else {
      this.labelInput += translatedDisplayNames;
    }
  }

  private resetSelectedOptions() {
    this.selectOptionsSubject.next(this.selectOptions);
    this.selectOptions.forEach((v) => {
      v.isSelected = false;
    });
  }

  private closeFilter() {
    if (this.filterToggle) {
      this.filterToggle.nativeElement.checked = false;
      this.clearSearchBox();
    }
  }

  private clearSearchBox() {
    this.filterInput.setValue('');
  }
}
