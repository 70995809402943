<div class="d-flex ml-2">
  <div class="ox-form__group mb-0 ">
    <select (ngModelChange)="onChange()" [formControl]="datePickerForm" class="ox-form__field date-filter-button"
            id="date-picker-dropdown">
      <option *ngFor="let option of dateOptions" [value]="option">
        {{ 'model.filter.DATE_PICKER.' + option | translate }}
      </option>
    </select>
  </div>
  <div [ngClass]="{ 'd-none': !showDatePicker }" class="ox-form__group mb-0 ml-2">
    <input (change)="onCustomDateChange()"
           [formControl]="customDate"
           class="ox-form__field date-filter-button"
           id="date-picker-custom"
           type="date"/>
  </div>
</div>
