import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {BroadcastingService} from './broadcasting.service';
import {TranslateService} from '@ngx-translate/core';
import {APP_CONFIG, AppConfig} from '../config/app-config';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements OnDestroy {
  private readonly _subscription: Subscription[] = [];

  private selectedOpco: string;
  private selectedLanguage: string;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly broadcastingService: BroadcastingService,
    private readonly translateService: TranslateService
  ) {
    this._subscription.push(broadcastingService.onOpcoCodeChanged.subscribe(selectedOpco => {
        this.selectedOpco = selectedOpco;
      }),
      translateService.onLangChange.subscribe(({lang}) => {
        this.selectedLanguage = lang;
      })
    );
  }

  public qplanningTourUrl(tourExternalId: string) {
    return `${this.appConfig.urls.qplanning}/${this.selectedOpco}/transports?tourExternalId=${tourExternalId}`;
  }

  public qplanningTransportUrl(transportId: string) {
    return `${this.appConfig.urls.qplanning}/${this.selectedOpco}/transports?id=${transportId}`;
  }

  getCookieURL() {
    return `https://policy.qone.mateco.eu/cookie?opco=${this.selectedOpco}&lang=${this.selectedLanguage}`;
  }

  getPrivacyURL() {
    return `https://policy.qone.mateco.eu/privacy?opco=${this.selectedOpco}&lang=${this.selectedLanguage}`;
  }

  ngOnDestroy(): void {
    this._subscription.forEach(subscription => subscription.unsubscribe());
  }
}
