import {SortingStrategy} from './sorting-strategy';
import {DeliveryStatus} from '../../models/delivery-status.model';
import {SortingOrder} from '../../models/enums/SortingOrder';
import {GeneralStatus, Status} from '../../models/status.model';

export class GeneralStatusSortingStrategy extends SortingStrategy {

  constructor() {
    super((record) => record.status);
  }

  sort(records: DeliveryStatus[], sortingOrder: SortingOrder): DeliveryStatus[] {
    // null values should be put at the end of the sorting
    switch (sortingOrder) {
      case SortingOrder.ASCENDING:
        return records.sort((a, b) =>
          this.getOrder(this.sortingField(a)) - this.getOrder(this.sortingField(b)));
      case SortingOrder.DESCENDING:
        return records.sort((a, b) =>
          this.getOrder(this.sortingField(b)) - this.getOrder(this.sortingField(a)));
      default:
        return records;
    }
  }

  /**
   *  To be able to sort the list in the requested order, each enum value has it's own numeric value.
   *  In this case, when the lastStop is TRUE and the generalStatus is either HANDLING or IN_TRANSIT,
   *  we assign a lower numeric value to indicate that these records should be put before the "normal" HANDLING and IN_TRANSIT statuses.
   */
  getOrder(status: Status) {
    switch (status.generalStatus) {
      case GeneralStatus.FREE:
        return 1;
      case GeneralStatus.NOT_STARTED:
        return 2;
      case GeneralStatus.HANDLING:
        return status.isLastStop ? 3 : 5;
      case GeneralStatus.IN_TRANSIT:
        return status.isLastStop ? 4 : 6;
      case GeneralStatus.FINISHED:
        return 7;
      default:
        return 0;
    }
  }
}
