import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Tour} from '../../../models/tour.model';
import {Stop} from '../../../models/stop.model';
import {ActionPoint} from '../../../models/actionpoint.model';
import {StopStatus} from '../../../models/enums/StopStatus';
import {DateUtilService} from 'src/app/services/date-util.service';
import {EnvironmentService} from '../../../services/environment.service';
import {DateTime} from 'luxon';
import {UnloadCargoIssue} from '../../../models/unload-cargo-issue.model';

@Component({
  selector: 'app-status-detail',
  templateUrl: './status-detail.component.html',
  styleUrls: ['./status-detail.component.scss']
})
export class StatusDetailComponent implements OnChanges {

  /**
   * The list of {@link Tour}s to visualize in detail.
   * This contains unfinished, planned and upcoming {@link Tour}s.
   */
  @Input() tours: Tour[];

  /**
   * Enhanced list of {@link Tour}s to make the visualization logic easier to manage.
   */
  enhancedTours: EnhancedTour[];

  constructor(private readonly dateService: DateUtilService,
              private readonly environmentService: EnvironmentService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tours) {
      const tours = changes.tours.currentValue as Tour[];

      // Ensures tours are enhanced only once instead of multiple times during rendering.
      this.enhancedTours = this.enhanceTours(tours);
    }
  }

  tourUrl(externalTourId: string) {
    return this.environmentService.qplanningTourUrl(externalTourId);
  }

  tourId(index: number, item: EnhancedTour) {
    return item.externalTourId;
  }

  verticalLineClass(isFirstStop: boolean, isLastStop: boolean) {
    if (isFirstStop && isLastStop) {
      return '';
    } else if (isFirstStop) {
      return 'vertical-line vertical-line-only-bottom';
    } else if (isLastStop) {
      return 'vertical-line vertical-line-only-top';
    } else {
      return 'vertical-line';
    }
  }

  allActionPointsFinishedAtDifferentDate(stop: Stop | null) {
    if (stop && stop.actionPoints && stop.stopStatus === StopStatus.FINISHED) {
      return stop.actionPoints.every(actionPoint =>
        this.dateService.isBeforeSelectedDate(actionPoint.finishedAt) ||
        this.dateService.isAfterSelectedDate(actionPoint.finishedAt));
    }
    return false;
  }

  isSingleActionPointFinishedAtDifferentDate(actionPoint: ActionPoint, stop: Stop | null) {
    return (
      this.dateService.isBeforeSelectedDate(actionPoint.finishedAt) ||
      this.dateService.isAfterSelectedDate(actionPoint.finishedAt)
    ) && !this.allActionPointsFinishedAtDifferentDate(stop);
  }

  private enhanceTours(tours: Tour[]) {
    return tours.map((tour) => {
      const isUnfinishedTour = this.dateService.isUnfinishedTour(tour);
      const isUpcomingTour = this.dateService.isUpcomingTour(tour);

      let filteredStops = tour.stops;
      if (isUnfinishedTour) {
        filteredStops = this.hideOldFinishedStops(tour.stops);
      }

      return {
        externalTourId: tour.externalTourId,
        departureDate: tour.departureDateTime.toLocaleString(DateTime.DATE_MED),
        isUnfinished: isUnfinishedTour,
        isPlanned: !isUnfinishedTour && !isUpcomingTour,
        isUpcoming: isUpcomingTour,
        isStarted: tour.started,
        stops: filteredStops,
        issues: tour.issues
      };
    });
  }

  stopId(index: number, item: Stop) {
    return item.plannedTimeOfArrival; // As unique as it gets since Stops have no IDs.
  }

  objectId(index: number, item: ActionPoint) {
    return item.objectId; // Unique within the scope of 1 Stop.
  }

  private hideOldFinishedStops(stops: Stop[]) {
    const result = [];

    let hasUnfinishedStopBeforeThisOne = false;
    for (const stop of stops) {
      if (stop.stopStatus !== StopStatus.FINISHED || this.hasActionPointCompletedToday(stop) || hasUnfinishedStopBeforeThisOne) {
        hasUnfinishedStopBeforeThisOne = true;
        result.push(stop);
      }
    }

    return result;
  }

  // TODO: Verify if we really need this check
  private hasActionPointCompletedToday(stop: Stop) {
    return stop.actionPoints.some(actionPoint => {
      return this.dateService.isAtSelectedDate(actionPoint.finishedAt);
    });
  }

  tourStopIcon(stopStatus: StopStatus) {
    switch (stopStatus) {
      case StopStatus.NOT_STARTED:
        return 'far fa-circle';
      case StopStatus.TRACKING:
        return 'far fa-dot-circle';
      case StopStatus.IN_PROGRESS:
        return 'far fa-check-circle';
      case StopStatus.ON_HOLD:
        return 'far fa-check-circle';
      case StopStatus.FINISHED:
        return 'fas fa-check-circle';
    }
  }
}

interface EnhancedTour {
  externalTourId: string;
  departureDate: string;
  isUnfinished: boolean;
  isPlanned: boolean;
  isUpcoming: boolean;
  isStarted: boolean;
  stops: Stop[];
  issues: UnloadCargoIssue[];
}
