import {Injectable} from '@angular/core';
import {RowStatus} from '../events/row-status';
import {BehaviorSubject} from 'rxjs';
import {ZonedDate} from '../models/ZonedDate';
import {DeliveryStatus} from '../models/delivery-status.model';
import {ApplicationStatus} from '../models/application-status';

@Injectable({
  providedIn: 'root'
})
/**
 * We use {@link BehaviorSubject}s to ensure that newly created components always receive the
 * latest status of the requested change (via their subscription). Even if that change was
 * triggered before the component was created.
 */
export class BroadcastingService {

  readonly onUnauthorized = new BehaviorSubject<boolean>(false);
  readonly onStatusChanged = new BehaviorSubject<ApplicationStatus>(ApplicationStatus.ONLINE);
  readonly onOpcoCodeChanged = new BehaviorSubject<string>(undefined);
  readonly onDateChanged = new BehaviorSubject<ZonedDate>(undefined);
  readonly onSearchChanged = new BehaviorSubject<string>(undefined);
  readonly onRowStatusChanged = new BehaviorSubject<RowStatus>(RowStatus.collapseAll());
  readonly filteredAndSortedRecords = new BehaviorSubject<DeliveryStatus[]>([]);
}
